import React, { memo, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { StringParam, useQueryParams, withDefault, NumberParam } from 'use-query-params';
import { loaded } from '../../utils/helpers';
import { graphql, useStaticQuery } from 'gatsby';

const FormNewsletter = memo((props) => {
  const [email, setEmail] = React.useState('');
  const {
    prismicStoreConfiguration: {
      data: { footer_newsletter_label, footer_newsletter_sub_label },
    },
  } = useStaticQuery(query);

  const [params] = useQueryParams({
    fNewsletter: withDefault(NumberParam, 0),
    result: withDefault(StringParam, ''),
    customer_posted: withDefault(StringParam, false),
  });

  useEffect(() => {
    if (params.customer_posted === 'true' && loaded) {
      location.hash = '#footer-newsletter';
    }
  }, [params]);

  return (
    <form
      id="footer-newsletter"
      action={`https://${process.env.GATSBY_SHOPIFY_DOMAIN_CHECKOUT}#contact`}
      acceptCharset="UTF-8"
      method="GET"
      className="form footer__container__subscribe__form -form-subscribe"
      onSubmit={() => {
        if (email.trim() == '' || email.indexOf('@') == -1) {
          alert('Please enter your email address');
          event.preventDefault();
          return;
        }
      }}
    >
      {/* <input type="hidden" name="userid" defaultValue={269233} />
            <input type="hidden" name="SIGce9446fb862b99d2dd6ceea1023fef6fdf5f8d8c1adba3d50d78fcc6f9ddf09d" defaultValue="" />
            <input type="hidden" name="addressbookid" defaultValue={2061434} /> */}
      {/* {typeof window != `undefined` && (
        <>
          <input type="hidden" name="ReturnURL" value={`${location.origin}?fNewsletter=1`} />
          <input type="hidden" name="ci_consenturl" value={location.origin} />
        </>
      )} */}

      <div className="form__header">
        <label htmlFor="input-footer-newsletter">{footer_newsletter_label}</label>
        <p className="des-newletter">{footer_newsletter_sub_label}</p>
      </div>
      <div>
        {(params.result === 'success' && params.fNewsletter) ||
        params.customer_posted === 'true' ? (
          <p style={{ fontSize: '14px', color: '#6acac6', fontWeight: 'bold' }}>
            Hooray! You're on the a-list
          </p>
        ) : (
          <div className="form__main">
            {/* <input type="hidden" name="form_type" defaultValue="customer" />
            <input type="hidden" name="utf8" defaultValue="✓" />
            <input type="hidden" name="contact[tags]" defaultValue="newsletter" /> */}
            <input
              id="input-footer-newsletter"
              placeholder="Enter your email here"
              name="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="btn--subscribe">
              subscribe
            </button>
          </div>
        )}
      </div>

      <div className="form__footer">
        <StaticImage
          width={200}
          height={27}
          sizes="200x27"
          src="../../images/payment-icons.png"
          alt="activeskin"
        />
      </div>
    </form>
  );
});
export default FormNewsletter;

const query = graphql`
  query FooterNewsletter {
    prismicStoreConfiguration {
      data {
        footer_newsletter_label
        footer_newsletter_sub_label
      }
    }
  }
`;
